import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Navbar from "./Components/Navbar/Navbar";
import styles from "./home.module.css";

export default function Home() {
  const messages = ["QR Codes!", "NFC Cards!"];
  const features = [
    "Website",
    "Text",
    "PDF",
    "Visiting Card",
    "WhatsApp",
    "Media",
    "Location",
    "UPI"
  ];
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [index, setIndex] = useState(0);
  const [currentFeature, setCurrentFeature] = useState(messages[0]);
  const [qrFeatureIndex, setQrFeatureIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("qr");

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Start fading out

      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setCurrentMessage(messages[index]);
        setQrFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
        setCurrentFeature(features[qrFeatureIndex]);
        setFade(false); // Start fading in
      }, 500); // Match this duration with the CSS fade-out duration
    }, 1500); // Change text every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
    // eslint-disable-next-line
  }, [index, qrFeatureIndex]);

  return (
    <>
      <Navbar />
      <div className={styles.homepage}>
        <div className={styles.banner}>
          <Container>
            <div className={styles.banner_text}>
              <h1>WELCOME!</h1>
              <h2>
                Transform your business with our suite of powerful{" "}
                <span
                  className={`${styles.text_rotator} ${
                    fade ? styles.fade_out : styles.fade_in
                  }`}
                >
                  {currentMessage}
                </span>
              </h2>
            </div>
          </Container>
        </div>

        <div className={styles.personalized_container}>
          <Container>
            <div className={styles.personalized_box}>
              <div className={styles.products}>
                <div
                  className={
                    selectedProduct === "qr" && styles.selected_product
                  }
                  onClick={() => setSelectedProduct("qr")}
                >
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M43.3333 46.6667V43.3333H33.3333V33.3333H43.3333V40H50V46.6667H46.6667V53.3333H40V60H33.3333V50H40V46.6667H43.3333ZM60 60H46.6667V53.3333H53.3333V46.6667H60V60ZM0 0H26.6667V26.6667H0V0ZM6.66667 6.66667V20H20V6.66667H6.66667ZM33.3333 0H60V26.6667H33.3333V0ZM40 6.66667V20H53.3333V6.66667H40ZM0 33.3333H26.6667V60H0V33.3333ZM6.66667 40V53.3333H20V40H6.66667ZM50 33.3333H60V40H50V33.3333ZM10 10H16.6667V16.6667H10V10ZM10 43.3333H16.6667V50H10V43.3333ZM43.3333 10H50V16.6667H43.3333V10Z"
                      fill="#047AB2"
                    />
                  </svg>
                </div>
                <div
                  className={
                    selectedProduct === "nfc" && styles.selected_product
                  }
                  onClick={() => setSelectedProduct("nfc")}
                >
                  <svg
                    width="70"
                    height="70"
                    viewBox="0 0 70 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 0H66.5C68.4331 0 70 1.74109 70 3.88889V66.1111C70 68.2589 68.4331 70 66.5 70H3.5C1.56702 70 0 68.2589 0 66.1111V3.88889C0 1.74109 1.56702 0 3.5 0ZM63 31.1111H6.99999V62.2222H63V31.1111ZM63 23.3333V7.77777H6.99999V23.3333H63ZM42 46.6667H56V54.4445H42V46.6667Z"
                      fill="#047AB2"
                    />
                  </svg>
                </div>
              </div>
              {selectedProduct === "qr" && (
                <div>
                  <div className={styles.personalized_heading}>
                    <p>
                      Create personalized QR Codes with features like{" "}
                      <span>{currentFeature}</span>
                    </p>
                  </div>
                  <center>
                    <br />
                    <img src="./images/personalized-qr.gif" alt="" />
                  </center>
                </div>
              )}
              {selectedProduct === "nfc" && (
                <div className={styles.personalized_gif}>
                  <img src="./images/personalized-nfc.gif" alt="" />
                </div>
              )}
            </div>
          </Container>
        </div>

        <div className={styles.our_specialization_container}>
          <Container>
            <center>
              <div className={styles.our_products_heading}>
                <p>
                  At our company, we specialize in developing powerful Products
                  that help business of all sizes streamline their operations,
                  increase productivity, and boost revenue
                </p>
              </div>
            </center>
            <div className={styles.qr_specialization}>
              <div>
                <img src="./images/qr_specialization.png" alt="" />
              </div>
              <div>
                <p style={{lineHeight:"40px" , fontSize:"25px", color:"#004367", fontWeight:"600"}}>
                <h3>Overview</h3>  
                  <p style={{fontWeight:"500", fontSize:"16px"}}>QR Codes are more than just quick links; they’re powerful tools for connecting businesses with audiences instantly. ScanOnn's QR code solutions offer unique designs, vCards, and smart packaging options that make sharing information effortless and engaging.</p>
                
                <h3>Applications</h3>
                  <p style={{fontWeight:"500", fontSize:"16px"}}>From business cards to product packaging, our QR codes open doors to instant information sharing, making your brand interactive and accessible anywhere. Whether for retail, events, or direct marketing, ScanOnn provides a scalable solution to meet your needs.</p>
                </p>
                <Button variant="primary">Explore more</Button>
              </div>
            </div>
            <div className={styles.nfc_specialization}>
              <div>
                <p style={{lineHeight:"40px" , fontSize:"25px", color:"#004367"}}>
                <h3>Overview</h3> 
                <p style={{fontWeight:"500", fontSize:"16px"}}>Near Field Communication (NFC) technology takes convenience a step further. Our NFC-enabled cards allow for effortless data sharing, simply by tapping the card on a compatible device. From payment systems to smart access, NFC is revolutionizing how people interact with the world.</p>
                
                <h3>Applications</h3>
                <p style={{fontWeight:"500", fontSize:"16px"}}>Perfect for professionals, businesses, and events, NFC cards streamline networking and enhance user experience. With ScanOnn’s NFC solutions, you’re at the forefront of digital interaction, ensuring your audience gets instant access to the information you want to share.</p>
                </p>
                <Button variant="primary">Explore more</Button>
              </div>
              <div>
                <img src="./images/nfc_specialization.png" alt="" />
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.qr_product_container}>
          <Container>
            <center>
              <div className={styles.our_products_heading}>
                <p>
                  Get the most out of our <span>products</span>
                </p>
              </div>
            </center>
            <div className={styles.qr_product_box}>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                  "Engage Customers Instantly with QR Code Solutions"
                </p>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    margin: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <li>
                    Custom Design Options: Tailor QR codes with branded colors,
                    logos, and frames.
                  </li>
                  <li>
                    Multi-Platform Integration: Seamlessly connect to websites,
                    social media, product information, and more.
                  </li>
                  <li>
                    Scalable and Versatile: Suitable for businesses of any size,
                    QR codes can be used for marketing, information sharing, and
                    tracking.
                  </li>
                  <li>
                    Dynamic QR Codes: Update the URL linked to the QR code
                    anytime without reprinting.
                  </li>
                  <li>
                    Enhanced User Engagement: Offer customers easy access to
                    product details, services, or promotions with a quick scan.
                  </li>
                </ul>
                <div style={{marginTop: "16px"}}>
                    <Button variant="primary">Explore more</Button>
                </div>
              </div>
              <div>
                <img src="./images/qr_product.png" alt="" />
                <br />
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.nfc_product_container}>
          <Container>
            <div className={styles.nfc_product_box}>
              <div>
                <img src="./images/nfc_product.png" alt="" />
                <br />
                <Button style={{ marginLeft: "50px" }} variant="primary">
                  Explore more
                </Button>
              </div>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "30px" }}>
                  "Effortless Connectivity with NFC Technology"
                </p>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    margin: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <li>
                    Touchless Interaction: Enable users to access information or
                    make purchases by simply tapping their devices.
                  </li>
                  <li>
                    Secure Data Exchange: NFC offers a secure way to share
                    information quickly and conveniently.
                  </li>
                  <li>
                    Customizable Applications: From smart payments to
                    personalized marketing, NFC adapts to your business needs.
                  </li>
                  <li>
                    Instant Accessibility: Information is instantly available
                    with a simple tap, enhancing user experience and engagement.
                  </li>
                  <li>
                    Environment-Friendly: NFC cards reduce the need for printed
                    materials, promoting a greener approach.
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.company_trust_container}>
          <Container>
            <div className={styles.company_trust_box}>
              <div className={styles.company_trust_heading}>
                <p>
                  Companies that <span>trust</span> us
                </p>
              </div>
              <div className={styles.company_trust}>
                <img src="./images/company1.png" alt="" />
                <img src="./images/company1.png" alt="" />
                <img src="./images/company1.png" alt="" />
                <img src="./images/company1.png" alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.testimonials_container}>
          <Container>
            <div className={styles.testimonial_oval}></div>
            <div className={styles.testimonials_box}>
              <div className={styles.testimonial_heading}>
                <p>
                  No matter which product you choose, you can count on our team
                  of experts to provide top-notch support and help you get the
                  most out of from us!
                </p>
                <div className={styles.pink_dot}></div>
              </div>
              <div className={styles.testimonials}>
                <div className={styles.testimonial}>
                  <div className={styles.stars}>
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </div>
                  <p className={styles.testimonial_text}>
                    "ScanOnn's QR solutions have transformed our restaurant
                    operations. Customers love the seamless experience, and we
                    appreciate the customization options that keep our brand
                    unique!"
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  </div>
                  <p className={styles.testimonial_name}>John D.</p>
                  <span>Owner, The Gourmet Kitchen </span>
                </div>
                <div className={styles.testimonial}>
                  <div className={styles.stars}>
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </div>
                  <p className={styles.testimonial_text}>
                    "Our students and parents love the interactive experience
                    with ScanOnn's NFC cards. It has allowed us to simplify
                    information sharing and track engagement effectively."
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  </div>
                  <p className={styles.testimonial_name}>P.Vallai</p>
                  <span>Marketing Head, EduTech Innovations</span>
                </div>
                <div className={styles.testimonial}>
                  <div className={styles.stars}>
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </div>
                  <p className={styles.testimonial_text}>
                    "With ScanOnn's QR codes, we’ve taken our marketing
                    campaigns to the next level. Easy to create, customize, and
                    use—they’ve helped us boost engagement and create lasting
                    impressions."
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  </div>
                  <p className={styles.testimonial_name}>Rajesh K.</p>
                  <span>Director, EcoPrint Solutions </span>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.testimonial_background}>
          <img src="./images/objects.png" alt="" />
          <img src="./images/oval.png" alt="" />
        </div>
      </div>
    </>
  );
}
