import axios from 'axios';
export const baseurl = process.env.REACT_APP_BASEURL;

const api = axios.create({
    baseURL: baseurl,
    headers: {
        'token': process.env.REACT_APP_TOKEN,
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default api;

// Fetch Vcard details
export const getVcard = '/getVcard/';

// Fetch website url
export const getWebsite = '/user/getQrWeb/';

// Get WhatsApp message
export const getWhatsAppQr = '/user/getWhatsAppQr/';

// Get Text and description
export const getQrText = '/user/getQrText/';

// Get Media url
export const getQrMedia = '/user/getQrMedia/';

// Get PDF url
export const getPdfQr = '/user/getPdfQr/';

// Get Location details
export const getGoogleMapsQr = '/user/getGoogleMapsQr/';

// Get UPI details
export const getUpiQr = '/user/qrUpi/';